import React from "react";

import { useWindowSize } from "../../app/Utilities/WindowSize";

import CompHeader from "../../assets/Images/header-carker-compromisos-taller.jpg";

import Comp1 from "../../assets/Images/comp_1.png";
import Comp2 from "../../assets/Images/comp_2.png";
import Comp3 from "../../assets/Images/comp_3.png";
import Comp4 from "../../assets/Images/comp_4.png";
import Comp5 from "../../assets/Images/comp_5.png";
import Comp6 from "../../assets/Images/comp_6.png";
import Comp7 from "../../assets/Images/verified_cm.png";

import { Helmet } from "react-helmet";

interface IProps {}

export const WorkshopCommitments: React.FC<IProps> = () => {
  const windowSize = useWindowSize();
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        ‍<title>Talleres CarKer | Como ser parte de CarKer</title>‍
        <meta
          name="description"
          content="Haz que tu taller sea parte de CarKer. Entérate de los requisitos para ser miembro de la red de talleres de mayor confianza de la Ciudad de México."
          data-react-helmet="true"
        />
        ‍
      </Helmet>
      <div
        id="workshopCommitmentsContain"
        className="workshopCommitmentsContain"
        style={{
          width: "100%",
          paddingTop: 0,
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <div
            className="commitments-hero-banner"
            style={{
              height:
                windowSize.width >= 1281
                  ? "500px"
                  : windowSize.width >= 450
                  ? "300px"
                  : "125px",
              marginTop: windowSize.width > 1200 ? 0 : 0,
              paddingTop:
                windowSize.width >= 1281
                  ? "207px"
                  : windowSize.width >= 1201
                  ? "117px"
                  : windowSize.width >= 769
                  ? "117px"
                  : windowSize.width >= 450
                  ? "117px"
                  : "47px",
              backgroundImage: `url(${CompHeader})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: windowSize.width >= 450 ? "cover" : "cover",
              backgroundPosition: "top right",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div className={"div-header-aboutus"}>
              <h1 className="white">Compromisos del taller</h1>
            </div>
          </div>

          <div
            style={{
              padding: "15px max(2%, 15px)",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems:
                  windowSize.width > 992 ? "flex-start" : "flex-start",
                justifyContent: "flex-start",
                //marginTop: "2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  width: windowSize.width > 992 ? "75px" : "55px",
                }}
              >
                <img
                  style={{
                    width: windowSize.width > 992 ? "75px" : "55px",
                    height: windowSize.width > 992 ? "75px" : "55px",
                    borderRadius: 5,
                  }}
                  className="blue-filter"
                  src={Comp1}
                  alt="Comp1"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  paddingLeft: windowSize.width > 992 ? "50px" : "0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3
                    className="orange"
                    style={{
                      marginLeft: 20,
                      marginBottom: 0,
                    }}
                  >
                    Para formar parte de la red selecta de talleres CarKer™,
                    cada taller debe cumplir con una serie de requisitos que
                    demuestren la seguridad, calidad y confiabiliadad de sus
                    servicios
                  </h3>
                </div>
                <p
                  className="medium_landing black"
                  style={{
                    marginLeft: 20,
                    marginBottom: 0,
                  }}
                >
                  Tras acreditar los procesos de evaluación y selección, los
                  talleres afiliados pactan aceptar y seguir estrictamente los
                  compromisos de CarKer™ para mantener siempre un servicio de
                  excelencia.
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              padding: "15px max(2%, 15px)",
              width: "100%",
              backgroundColor: "#2f66f2",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems:
                  windowSize.width > 992 ? "flex-start" : "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  width: windowSize.width > 992 ? "75px" : "55px",
                }}
              >
                <img
                  style={{
                    width: windowSize.width > 992 ? "75px" : "55px",
                    height: windowSize.width > 992 ? "75px" : "55px",
                    borderRadius: 5,
                  }}
                  src={Comp2}
                  alt="Comp2"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent:
                    windowSize.width > 992 ? "flex-start" : "center",
                  paddingLeft: windowSize.width > 992 ? "50px" : "0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <h2
                    className="white"
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    Ser transparente con el cliente
                  </h2>
                </div>
                <ul
                  className="medium_landing white"
                  style={{
                    content: "•",
                    marginLeft: "0px",
                    marginBottom: 0,
                  }}
                >
                  <li key={1}>
                    <span style={{ color: "white" }}>
                      Honestidad y claridad en diagnósticos y servicios.
                    </span>
                  </li>
                  <li key={2}>
                    <span style={{ color: "white" }}>
                      Informar al cliente de alternativas e implicaciones
                      potenciales.
                    </span>
                  </li>
                  <li key={3}>
                    <span style={{ color: "white" }}>
                      Obtener consentimiento antes de cualquier reparación.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            style={{
              padding: "15px max(2%, 15px)",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems:
                  windowSize.width > 992 ? "flex-start" : "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  width: windowSize.width > 992 ? "75px" : "55px",
                }}
              >
                <img
                  style={{
                    width: windowSize.width > 992 ? "75px" : "55px",
                    height: windowSize.width > 992 ? "75px" : "55px",
                    borderRadius: 5,
                  }}
                  src={Comp3}
                  alt="Comp3"
                  className="blue-filter"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent:
                    windowSize.width > 992 ? "flex-start" : "center",
                  paddingLeft: windowSize.width > 992 ? "50px" : "0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <h2
                    className="orange"
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    Precio justo
                  </h2>
                </div>
                <ul
                  className="medium_landing black"
                  style={{
                    content: "•",
                    marginLeft: "0px",
                    marginBottom: 0,
                  }}
                >
                  <li key={4}>
                    <span style={{ color: "black" }}>
                      Respetar las cotizaciones aprobadas vía CarKer™.
                    </span>
                  </li>
                  <li key={5}>
                    <span style={{ color: "black" }}>
                      Precios claros por mano de obra, refacciones y otros
                      servicios.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            style={{
              padding: "15px max(2%, 15px)",
              width: "100%",
              backgroundColor: "#2f66f2",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems:
                  windowSize.width > 992 ? "flex-start" : "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  width: windowSize.width > 992 ? "75px" : "55px",
                }}
              >
                <img
                  style={{
                    width: windowSize.width > 992 ? "75px" : "55px",
                    height: windowSize.width > 992 ? "75px" : "55px",
                    borderRadius: 5,
                  }}
                  src={Comp4}
                  alt="Comp4"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent:
                    windowSize.width > 992 ? "flex-start" : "center",
                  paddingLeft: windowSize.width > 992 ? "50px" : "0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <h2
                    className="white"
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    Enfoque en la calidad
                  </h2>
                </div>
                <ul
                  className="medium_landing white"
                  style={{
                    content: "•",
                    marginLeft: "0px",
                    marginBottom: 0,
                  }}
                >
                  <li key={6}>
                    <span style={{ color: "white" }}>
                      Utilizar solo piezas y refacciones de calidad, tomando en
                      cuenta el manual del fabricante.
                    </span>
                  </li>
                  <li key={7}>
                    <span style={{ color: "white" }}>
                      Dar garantía escrita* por un período o kilometraje
                      delimitado.
                    </span>
                  </li>
                  <li key={8}>
                    <span style={{ color: "white" }}>
                      Buscar adquirir nuevos aprendizajes y mantener
                      capacitación constante.
                    </span>
                    <p className="small white">
                      *A excepción de partes eléctricas y/o electrónicas.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            style={{
              padding: "15px max(2%, 15px)",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems:
                  windowSize.width > 992 ? "flex-start" : "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  width: windowSize.width > 992 ? "75px" : "55px",
                }}
              >
                <img
                  style={{
                    width: windowSize.width > 992 ? "75px" : "55px",
                    height: windowSize.width > 992 ? "75px" : "55px",
                    borderRadius: 5,
                  }}
                  src={Comp5}
                  alt="Comp5"
                  className="blue-filter"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent:
                    windowSize.width > 992 ? "flex-start" : "center",
                  paddingLeft: windowSize.width > 992 ? "50px" : "0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <h2
                    className="orange"
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    Inclusión
                  </h2>
                </div>
                <ul
                  className="medium_landing black"
                  style={{
                    content: "•",
                    marginLeft: "0px",
                    marginBottom: 0,
                  }}
                >
                  <li key={9}>
                    <span style={{ color: "black" }}>
                      Respetar a todos los clientes independientemente de su
                      género, edad, sexo, preferencia, condición social,
                      religión o cualquier otra razón.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            style={{
              padding: "15px max(2%, 15px)",
              width: "100%",
              backgroundColor: "#2f66f2",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems:
                  windowSize.width > 992 ? "flex-start" : "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  width: windowSize.width > 992 ? "75px" : "55px",
                }}
              >
                <img
                  style={{
                    width: windowSize.width > 992 ? "75px" : "55px",
                    height: windowSize.width > 992 ? "75px" : "55px",
                    borderRadius: 5,
                  }}
                  src={Comp6}
                  alt="Comp6"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent:
                    windowSize.width > 992 ? "flex-start" : "center",
                  paddingLeft: windowSize.width > 992 ? "50px" : "0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <h2
                    className="white"
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    Respeto a compromisos
                  </h2>
                </div>
                <ul
                  className="medium_landing white"
                  style={{
                    content: "•",
                    marginLeft: "0px",
                    marginBottom: 0,
                  }}
                >
                  <li key={10}>
                    <span style={{ color: "white" }}>
                      Confirmar las solicitudes de citas por medio de la
                      plataforma CarKer™.
                    </span>
                  </li>
                  <li key={11}>
                    <span style={{ color: "white" }}>
                      Realizar solo servicios aprobados por el cliente.
                    </span>
                  </li>
                  <li key={12}>
                    <span style={{ color: "white" }}>
                      Respetar los horarios acordados con cliente, desde la
                      recepción hasta la entrega del vehículo.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            style={{
              padding: "15px max(2%, 15px)",
              width: "100%",
              backgroundColor: "var(--black-70)",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems:
                  windowSize.width > 992 ? "flex-start" : "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  width: windowSize.width > 992 ? "75px" : "55px",
                }}
              >
                <img
                  style={{
                    width: windowSize.width > 992 ? "75px" : "55px",
                    height: windowSize.width > 992 ? "75px" : "55px",
                    borderRadius: 5,
                  }}
                  src={Comp7}
                  alt="Comp7"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent:
                    windowSize.width > 992 ? "flex-start" : "center",
                  paddingLeft: windowSize.width > 992 ? "50px" : "0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="medium_landing white"
                    style={{
                      marginLeft: 20,
                      marginBottom: 0,
                    }}
                  >
                    Seguir estos compromisos te ayudará a que tus clientes sigan
                    reconociéndote como un profesional confiable, comprometido y
                    experto en el cuidado automotriz.
                  </p>
                </div>

                <h2
                  className="white"
                  style={{
                    marginTop: 20,
                    marginLeft: 20,
                    marginBottom: 0,
                  }}
                >
                  ¡Apégate a ellos y muestra el trabajo de excelencia que te
                  distingue!
                </h2>
                <p
                  className="medium_landing white"
                  style={{
                    marginTop: 20,
                    marginLeft: 20,
                    marginBottom: 0,
                  }}
                >
                  El incumplimiento de alguno o algunos de los compromisos,
                  podrá resultar en el retiro del taller de la plataforma
                  CarKer™. Esto con la finalidad de cuidar la reputación de todos
                  los talleres que forman parte de este grupo selecto. Seguir
                  estos compromisos te ayudará a que tus clientes sigan
                  confiando en los talleres automotrices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
