import React from "react";
import { Col, Collapse, Row } from "antd";
import "./MobilContigoStyle.css";
import { useDispatch } from "react-redux";
import { setCurrentSection } from "../../stores";
import UsesOfCarKerHeader from "../../assets/Images/header-carker-tips.jpg";
import Mechanix from "../../../src/assets/Images/Mechanix2.png";
import HandIco from "../../assets/Images/comp_1.png";

import { useWindowSize } from "../../app/Utilities/WindowSize";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

interface IProps {}

export const UsesOfCarKer: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  React.useEffect(() => {
    dispatch(setCurrentSection("Usos de CarKer"));
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        ‍<title>Usos De CarKer™ | CarKer™ Para Talleres</title>‍
        {/* <meta name="description" content="Qué es CarKer. Forma parte de nuestra comunidad selecta de talleres e impulsa tu negocio" data-react-helmet="true" />‍ */}
      </Helmet>

      <div
        id="usesOfCarKerContain"
        className="usesOfCarKerContain"
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            paddingTop: 0,
          }}
        >
          <div
            style={{
              height:
                windowSize.width >= 1281
                  ? "500px"
                  : windowSize.width >= 450
                  ? "300px"
                  : "125px",
              marginTop: windowSize.width > 1200 ? 0 : 0,
              paddingTop:
                windowSize.width >= 1281
                  ? "207px"
                  : windowSize.width >= 1201
                  ? "117px"
                  : windowSize.width >= 769
                  ? "117px"
                  : windowSize.width >= 450
                  ? "117px"
                  : "47px",
              backgroundImage: `url(${UsesOfCarKerHeader})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: windowSize.width >= 450 ? "cover" : "450px 150px",
              backgroundPosition: "top right",
            }}
          >
            <div className={"div-header-left"}>
              <h1 className="white">Usos de CarKer™</h1>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              padding: "15px max(2%, 15px)",
            }}
          >
            <img
              style={{
                width: windowSize.width > 991 ? "100px" : "65px",
                height: windowSize.width > 991 ? "100px" : "65px",
                marginRight: "15px",
              }}
              src={HandIco}
              alt="HandIco"
              className="blue-filter"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h3 className="orange">
                ¿Quieres aprovechar CarKer™ al máximo?
                <br />
                Conoce algunas de sus funciones
              </h3>
            </div>
          </div>

          <Row
            style={{
              backgroundColor: "white",
              width: "100%",
              padding: "15px max(2%, 15px)",
            }}
            align="middle"
            justify="center"
          >
            <Col
              span={24}
              style={{ textAlign: "left", backgroundColor: "white" }}
            >
              <Collapse
                bordered={false}
                expandIconPosition="right"
                style={{
                  marginTop: 0,
                  backgroundColor: "white",
                }}
                accordion
              >
                <Collapse.Panel
                  key={0}
                  header={
                    <span>
                      ¿Por qué <b>CarKer™</b> es lo que tu taller necesita?
                    </span>
                  }
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    CarKer™ es la forma de transformar tu negocio y atraer a
                    nuevos clientes con ayuda de la tecnología. Al registrarte,
                    aumentas las posibilidades de que más personas encuentren tu
                    taller, conozcan tus servicios y agenden una cita
                    directamente contigo.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={1}
                  header={
                    <span>
                      ¿Cómo <b>CarKer™</b> puede ayudar a crecer tu negocio?
                    </span>
                  }
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    Al registrarte, nuevos clientes que se encuentren cerca de
                    tu negocio podrán encontrarte, conocer los servicios que
                    ofreces, revisar lo que otras personas han dicho de tu
                    taller y agendar citas contigo. Además, puedes administrar
                    tu agenda, aceptar citas directamente en la plataforma y
                    notificar a tu cliente cuando su auto esté listo.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={2}
                  header={
                    <span>
                      ¿Cómo registrar tu taller en <b>CarKer™</b>?
                    </span>
                  }
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    ¡Es muy sencillo! Puedes registrarte y crear una cuenta en
                    nuestro sitio web www.taller.carker.com.mx , o bien,
                    descargar la aplicación de CarKer™, disponible en todas las
                    tiendas de aplicaciones (esta aplicación es exclusiva para
                    el uso de los talleres). Al concluir tu registro, completa
                    el perfil de tu taller, así como el proceso de aprobación de
                    tu taller. Tendrás que confirmar estar de acuerdo con
                    nuestros T&C y confirmar que tu taller se compromete a
                    cumplir con los “Compromisos del taller CarKer™”.{" "}
                    <Link
                      to={{
                        pathname: "/compromisos-talleres",
                      }}
                      // onClick={scrollToMap}
                      className="a"
                    >
                      Aquí
                    </Link>{" "}
                    los describimos para ti.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={3}
                  header={
                    <span>
                      ¿Qué funciones tiene <b>CarKer™</b>?
                    </span>
                  }
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    CarKer™ tiene diferentes funcionalidades que te ayudarán a
                    mejorar la productividad en tu negocio.
                  </p>
                  <p>Conoce todas las funciones disponibles en la plataforma</p>
                  <p>
                    <b>Administrar taller: </b>Aquí podrás actualizar la
                    información de tu taller así como fotografías, dirección,
                    servicios que ofreces, precios, datos de contacto, etc.
                  </p>
                  <p>
                    <b>Administrar agenda: </b>Tendrás acceso a un calendario
                    donde podrás registrar citas, recibir citas de nuevos
                    clientes, confirmarlas, reagendarlas y/o cancelarlas;
                    también podrás visualizar el estatus de los servicios que se
                    encuentren en recepción, diagnóstico, reparación, terminados
                    o entregados. No olvides echarle un ojo a esta parte para
                    aceptar tus citas y actualizar el estatus de ellas para
                    mantener al tanto a tu cliente del servicio que le estas
                    brindando.
                  </p>
                  <p>
                    <b>Administrar mecánicos: </b>En esta sección podrás invitar
                    y ver a quiénes de tu equipo tienes dados de alta en CarKer™
                    y si se requiere, agregar, eliminar o cambiar accesos. De
                    esta forma, todo tu equipo podrá colaborar dentro de la
                    plataforma.
                  </p>
                  <p>
                    <b>Consultas de clientes: </b>En esta sección encontrarás
                    todas las solicitudes que los clientes te envíen. Las cuales
                    pueden ser cotizaciones o consultas en general. Es muy
                    importante que estés al pendiente de las consultas que te
                    lleguen para responder lo antes posible a tus próximos
                    clientes.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={4}
                  header="Suma a tu equipo de trabajo y lleva el control de tu taller desde cualquier dispositivo"
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    ¡No dejes fuera de la familia a nadie! Al registrar tu
                    taller en la plataforma, no olvides dar de alta a todos los
                    miembros de tu taller, de esta forma, tendrán acceso a
                    ciertas funciones, como cambiar el estatus de los servicios
                    y mantenerse actualizados sobre todas las citas pendientes.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={5}
                  header={
                    <span>
                      ¿Cuál es la diferencia entre el rol del dueño,
                      administrador y el mecánico en <b>CarKer™</b>?
                    </span>
                  }
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    Si eres propietario o encargado del taller registrado, serás
                    reconocido como “Dueño”. Como dueño, podrás dar de alta tu
                    taller, cambiar la información de la cuenta y del taller,
                    agregar a otros trabajadores bajo el rol de “Mecánico” y
                    “Admin”.
                  </p>
                  <p>
                    El Admin, te podrá apoyar registrar los datos del taller,
                    crear citas, confirmarlas y cambiar su estatus.
                  </p>
                  <p>
                    El Mecánico, solo podrá ver la información del taller sin
                    modificarla, ver las citas y actualizar su estatus, pero no
                    podrá aceptarlas o generar nuevas citas.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={6}
                  header="Solicitudes, administración de citas y seguimiento online"
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    ¡Con CarKer™ tienes todo en un solo lugar! Mantén el control
                    de todos los servicios de tu taller en una sola plataforma,
                    gestiona las solicitudes y aprobación de citas, da
                    seguimiento y monitorea el estatus de los vehículos.
                    Descarga ya la app de CarKer™.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={7}
                  header="¿Cuáles son las etapas de un servicio?"
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    Desde el momento en que un nuevo cliente encuentre tu
                    taller, podrá realizarte consultas y cotizaciones, las
                    cuales podrás ver en la plataforma, sin embargo, el servicio
                    dará inicio una vez que el taller confirme una cita y
                    terminará cuando cambies el estatus a “entregado” y el
                    cliente reciba su vehículo.
                  </p>
                  <p>
                    Para mantener al tanto a tu cliente sobre el servicio, es
                    importante actualizar en la plataforma la etapa en la que se
                    encuentra: recepción, diagnóstico, reparando, terminado o
                    entregado. Al completar cada etapa, tu cliente será
                    notificado vía correo y SMS sobre el avance del servicio de
                    su vehículo. Recuerda que al recibir su vehículo, los
                    usuarios podrán hacer una evaluación de tu taller y enviar
                    comentarios para futuros clientes.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={8}
                  header="Ventajas de mantener a tu cliente actualizado sobre el servicio de su vehículo"
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    Un gran poder conlleva una gran responsabilidad: Al utilizar
                    la plataforma de CarKer™, tus clientes conocen en tiempo real
                    el estatus de su servicio, lo que se refleja en la
                    transparencia de tu servicio y aumenta la confianza y
                    tranquilidad de tus clientes.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={9}
                  header="Hazte visible y deja que nuevos usuarios encuentren tu taller desde su celular"
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    ¡Deja que cientos de usuarios en la ciudad conozcan tu
                    taller! Al registrarte en CarKer™, las posibilidades de que
                    nuevos clientes encuentren tu taller aumenta. Nuestro
                    algoritmo funciona con base en la localización de los
                    usuarios y los servicios que cada taller ofrece, por lo que
                    te encontrarán cuando ellos estén cerca de tu negocio,
                    cuando busquen uno de los servicios con los que tu taller
                    cuenta y también por el nombre de tu taller.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={10}
                  header="Recibe respaldo y asesoría constante"
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    CarKer™ es la primera startup en México con una red de
                    talleres que conecta a expertos como tú con nuevos clientes.
                    Al formar parte de CarKer™, formas parte de una comunidad
                    automotriz que garantiza la calidad de tu servicio, sólo los
                    mejores talleres se encuentran en CarKer™. Por lo que si
                    necesitas ayuda o tienes cualquier duda con la plataforma
                    estamos aquí para apoyarte, contacta a nuestro equipo de{" "}
                    <Link
                      to={{
                        pathname: "/contacto",
                      }}
                      // onClick={scrollToMap}
                      className="a"
                    >
                      Servicio a Cliente
                    </Link>
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={11}
                  header="Consulta las opiniones de los clientes sobre tu taller y sigue mejorando"
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    Todo lo que se mide se puede mejorar, por eso nos encanta la
                    retroalimentación de los usuarios. Al concluir un servicio
                    en tu taller, tus clientes podrán contarte qué les pareció
                    la experiencia y si existen detalles para ofrecer una mejor
                    experiencia. Esto también ayudará a que más usuarios nuevos
                    tengan referencia de tus clientes para aumentar la confianza
                    de tu taller y agenden una cita.
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={12}
                  header={
                    <span>
                      Apóyate en los compromisos de <b>CarKer™</b>
                    </span>
                  }
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    La calidad es nuestra mejor garantía. Asegúrate de cumplir
                    con todos nuestros Compromisos del Taller para formar parte
                    de la comunidad CarKer™ y del grupo más selecto de talleres
                    en el país. Nos queremos asegurar que todos los talleres
                    cumplan con ellos. Consúltalos{" "}
                    <Link
                      to={{
                        pathname: "/compromisos-talleres",
                      }}
                      // onClick={scrollToMap}
                      className="a"
                    >
                      Aquí
                    </Link>
                  </p>
                </Collapse.Panel>
                <Collapse.Panel
                  key={13}
                  header={
                    <span>
                      En qué ciudades está disponible <b>CarKer™</b>
                    </span>
                  }
                  className="collapse-faq-color"
                >
                  <p
                    style={{
                      marginTop: "-10px",
                    }}
                  >
                    Por el momento CarKer™ se encuentra disponible en toda la
                    Zona Metropolitana del Valle de México y otros lugares del
                    país. ¡Mantente al pendiente de nuestras redes sociales!
                  </p>
                </Collapse.Panel>
              </Collapse>
            </Col>
          </Row>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "15px max(2%, 15px)",
              backgroundColor: "var(--black-70)",
              width: "100%",
              //height: "190px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  height: windowSize.width > 766 ? "100px" : "50px",
                  paddingRight: "2%",
                }}
                src={Mechanix}
                alt=""
              />
              <h4 className="white">
                ¡Forma parte de nuestra comunidad de talleres selectos!
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
